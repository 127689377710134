<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
    <!-- Contenedor del modal -->
    <div class="w-full h-full bg-white shadow-xl overflow-hidden">
      <!-- Header -->
      <div class="bg-indigo-700 py-6 px-4 sm:px-6 flex justify-between items-center">
        <h2 class="text-2xl font-bold text-white">   {{ objJaula.descripcion }}</h2>
        <button @click="close_modal" class="text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      <!-- Contenido -->
      <div class="p-4 h-full">
      
        <GraficoUnidadHighChart 
        v-if="this.$store.state.gotolive !== true"
        :noShowSetpoint="noShowSetpoint" 
        :startDate="startDate"
                  :endDate="endDate"  :objJaula="objJaula"></GraficoUnidadHighChart>

      </div>
    </div>
  </div>
</template>

  
  
  <script>
 import GraficoUnidadHighChart from "../GraficoUnidadHighChart";
  export default {
    components: {
      GraficoUnidadHighChart
  
    },
 
    create: {
  
    },
    props: ["objJaula" ,"noShowSetpoint", "startDate", "endDate", ],
    emits: ["close"],
    data: () => ({
      visible: false,
      latitud: 0,
      longitud: 0,
    }),
  
    mounted() {
     
    },
    methods: {
     
      close_modal() {
        console.log('cerrar modal 1');
        this.$emit("close")
      }
  
    },
    watch: {
     
    }
  }
  </script>