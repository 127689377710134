<template>
  <div class="p-0 mt-3">
 
    <VueTailwindDatepicker 
    :autoApply="true" :shortcuts="false" :useRange="useRange" i18n="es" :formatter="formatter"
      :startFrom="startFrom"  
      v-model="dateValue" 
      @update:modelValue="handleApply" />


  </div>
</template>


<script>
import { ref } from 'vue'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'


export default {
  components: {
    VueTailwindDatepicker
  },
  props: ['fechasSeleccionadas','propUseRange'],
  emits: ['getFecCalendar'],
  mounted() {
    
  },
  data() {
    return {
      showRegularDate: true,
      checkin: '',
      useRange: this.propUseRange==false ? false : true,
      startFrom: '',
      dateValue:  ref(''),
      formatter: ref({
        date: 'DD MM YYYY',
        month: 'MMM',

      }),


    }
  },
  watch: {
    fechasSeleccionadas(value) {
      this.dateValue = []
      this.startFrom = value[0]
      this.dateValue = value

    },
    dateValue() {

      this.getFecCalendar();
    },
  },
  methods: {
    closeDatePickerPopup() {
   
    
      
    },
    
    handleApply(){
     
      console.log(this.dateValue, "<--");
      this.$emit("getFecCalendar", this.dateValue)
    },
    getFecCalendar() {
      console.log(this.dateValue, "<--");
      this.$emit("getFecCalendar", this.dateValue)
    }
  },
}
</script>