<template>
<div>
 <ModalPeriodoConsumoOxigeno :show="showModal"  :seriesFaenas="seriesFaenas"  @close="closeModal" > </ModalPeriodoConsumoOxigeno>
<span class="font-semibold "> Consumo Ox&iacute;geno</span>
 
    
                <table   class="divide-gray-300  border-spacing-0 m-0">
                <tbody class="divide-y divide-gray-200 bg-white ">

                        <tr>
                            <td class="whitespace-nowrap py-0    text-xs   text-gray-900 ">Hoy {{ diahoy}} :</td>
                            <td class="whitespace-nowrap px-3  pl-2 py-2 text-xs text-gray-700"> {{  this.valores[0] }}  ton</td>

                        </tr>

                        <tr>
                            <td class="whitespace-nowrap py-0   text-xs   text-gray-900">Mes en curso:</td>
                            <td class="whitespace-nowrap px-3 pl-2 py-2 text-xs text-gray-700">{{  this.valores[1] }}  ton</td>

                        </tr>
                        <tr>
                            <td class="whitespace-nowrap py-0  text-xs   text-gray-900 ">Período a contar:</td>
                            <td class="whitespace-nowrap px-3  pl-2 py-2 text-xs text-gray-700"> {{  this.valores[2] }}  ton 
                        
                                
                            </td>
                        </tr>

                   
                
                    </tbody>
                </table>
    </div>
 
</template>
<script>
   import ModalPeriodoConsumoOxigeno from "../historial/old/ModalPeriodoConsumoOxigeno.vue";
    export default {
        data(){
            return{
                visible:false,
                showModal: false,
                valores:Array,
                diahoy:'',
                mescurso:'',
                nomMes: ['', 'Jan', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            }
        },
        components:{
            ModalPeriodoConsumoOxigeno
        },
        props:["idFaena","consumo_oxigeno","seriesFaenas"],
        mounted(){
           

           switch(this.idFaena){
            case 1: this.valores=["3,1", "31,1", "35,2"];break;
            case 2: this.valores=["2.8","27.9","31,7"];break;
            case 3: this.valores=["3,1", "31,1", "35,2"];break;
            case 4: this.valores=["3,1", "31,1", "35,2"];break;
            case 5: this.valores=["2.8","27.9","31,7"];break;
            case 6: this.valores=["3,1", "31,1", "35,2"];break;
            case 7: this.valores=["3,1", "31,1", "35,2"];break;
           }


           var fechaActual = new Date();
            var año = fechaActual.getFullYear();
            var mes = fechaActual.getMonth() + 1; // Los meses van de 0 a 11, por lo que sumamos 1
            var dia = fechaActual.getDate();

            // Formatear la fecha en el formato deseado (por ejemplo, YYYY-MM-DD)
            this.mescurso=this.nomMes[Number(mes)]
            this.diahoy =   ((dia < 10 ? '0' : '') + dia )+ ' ' + this.nomMes[Number(mes)]  + ' ' + año;

        },
        methods:{
            modalPeriodos(){
                console.log('modal periodos')
                this.showModal=true;
            },
            closeModal() {
            this.showModal = false
            console.log('cerrar modal 2');
            }
        }
    } 
</script>