<template>
  <div>

    <iframe style="width: 100%;" width="100%" height="532" frameborder="0" :src="mapUrl" scrolling="no">
    </iframe>



  </div>
</template>
<script>

export default {
  props: ["propLatitud", "propLongitud"],
  data() {
    return {
      latitud: this.propLatitud,
      longitud: this.propLongitud,
    };

  },

  computed: {
    mapUrl() {
      return `https://www.bing.com/maps/embed?h=532&w=988&cp=${this.latitud}~${this.longitud}&lvl=11&typ=d&sty=r&src=SHELL&FORM=MBEDV8`;
    }
  }
}
</script>