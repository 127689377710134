<template>

    <a href="#"  class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
        <div class="flex md:h-full lg:flex-col">
            <div class="flex-shrink-0">
                <div
                    class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                    </svg>
                </div>
            </div>
            <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                <div>
                    <p class="text-xs text-gray-900" >Sin Layout</p>

                </div>

            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: 'btnMapa',
    emits: ['emitMapa'],
    methods: {
        mapa() {
            this.$emit('emitMapa')
        }
    }
}
</script>