 
<template>
  <div class="px-4 my-12">
 
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="{]}xl font-semibold {]}gray-900">Cuentas de Usuario</h1>
        <p v-if="msgApi" class="mt-2 {]}sm   {]}red-700">
          {{ msgApi }} 
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
       
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                    <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 {]}center {]}sm font-semibold {]}gray-900 sm:pl-6"
                  >
                  Empresa
                  </th>
                 
                  <th
                    scope="col"
                    class="px-3 py-3.5 {]}center {]}sm font-semibold {]}gray-900"
                  >
                   E-Mail
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 {]}center {]}sm font-semibold {]}gray-900"
                  >
                    Nombre
                  </th>

                 

                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Del</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="person in lstUsuarios" :key="person.id">
                
                 <td class="whitespace-nowrap px-3 py-4 {]}sm {]}gray-500">
                    <div class="{]}gray-900">{{ person.razonSocial }}</div>
                  </td>

                  
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 {]}sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="font-medium {]}gray-900">
                          {{ person.email }}
                        </div>
                        <!-- <div class="{]}gray-500">{{ person.id }}</div>-->
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 {]}sm {]}gray-500">
                    <div class="{]}gray-900">{{ person.nombres }} {{ person.apellido1 }}</div>
                  </td>

                 
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 {]}right {]}sm font-medium sm:pr-6"
                  >
                    <button
                      type="button"
                      v-on:click="editUsuario(person.id)"
                      class="ml-3 inline-flex bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm {]}sm font-medium {]}gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Editar
                    </button>

                    
                  </td>
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 {]}right {]}sm font-medium sm:pr-6"
                  >
                    <button
                      type="button"
                      v-on:click="deleteUsuario(person.id)"
                      class="ml-3 inline-flex bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm {]}sm font-medium {]}gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Borrar
                    </button>

                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
export default {
  props: ["userEdit", "listUsuarios"],
  emits: ["editUsuario","deleteUsuario", "chgNuevoUsuario"],
  
  
  mounted() {
    this.loadData();
  } ,
  data() {
    return {
      msgApi: '',
      listUsers: ref(this.listUsuarios),
      lstUsuarios: []
    };
  },
  methods: {
    async loadData() {
      try {
        const apiKey = this.$store.state.login.tokenLogin;

        const data = {};
        const url = "/api/admin/getUsers";
        const resp = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${apiKey}`,
          },
        });
        if (resp.status === 200) 
        {
           
          switch (resp.data.status) {
            case 200:  
                this.lstUsuarios = resp.data.payload.users;
                this.$store.dispatch('setUsers',  this.lstUsuarios );
                 
                break;
            default:
               this.msgApi =  `${resp.data.status} ${resp.data.message}` ;
             // 
          }

         
        }


      } catch (err) {
        if (err.response) {
          
          const status = err.response.status;
          switch (status) {
            case 401:
              this.msgApi = "No autorizado. Por favor, verifica tus credenciales.";
              break;
            case 500:
              this.msgApi = "Error interno del servidor. Intenta nuevamente más tarde.";
              break;
            default:
              this.msgApi = `Error inesperado: ${status}`;
              break;
          }
        }
      }  
    },
   
    loadUsuario(id) {
      this.$emit("chgUsuario", id);
    },
    chgPassword(id) {
      this.$emit("chgPassword", id);
    },
    nuevoUsuario() {
      console.log("nuevo usuario");
      this.$emit("chgNuevoUsuario");
    },
    
    editUsuario(id){
        console.log("edit usuario");
        let arrusr= this.lstUsuarios.filter( usr => usr.id===id );
        console.log(arrusr[0],"***");
  

        this.$emit("editUsuario",arrusr[0]);
    },
    deleteUsuario(id){
       
        let arrusr= this.lstUsuarios.filter( usr => usr.id===id );
        

        this.$emit("deleteUsuario",arrusr[0]);
    }
  },
};
</script>
