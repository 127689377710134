 
import { createStore } from "vuex";
import usuarios from './usuarios';
import divisiones from './divisiones';
import login from './auth';
import faenas from './faenas';
import jaulas from './jaulas';
import draggdropp from './draggdropp';
 
const store = createStore({
  state: {
    user: Object,
    token: "",
    gotolive:true,
    objStatusJaulas: []
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    numtest: (state) => {
      return state.numtest;
    },
    JaulasStore: (state) => {
      return state.JaulasStore;
    },
     
  },
  actions: {
    user(context, user) {
      //  console.log('commit ',user)
      context.commit("user", user);
    },
    token(context, token) {
      console.log("commit ", token);
      context.commit("token", token);
    },
    JaulasStore(context, JaulasStore) {
      console.log("commit JaulasStore", JaulasStore);
      context.commit("JaulasStore", JaulasStore);
    },
    numtest(context, numtest) {
      console.log("commit ", numtest);
      context.commit("numtest", numtest);
    },
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
    numtest(state, numtest) {
      state.numtest = numtest;
    },
    JaulasStore(state, JaulasStore) {
        state.JaulasStore = JaulasStore;
      },
  },
  modules: {
    login,
    divisiones , 
    usuarios,
    faenas,
    jaulas,
    draggdropp
  },
});
export default store;
