<template>
  <div>
    <div class="text-center border border-yellow-500 p-2 m-1 flex items-center space-x-4 rounded-lg">
      <span><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
          stroke="#FFC107" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon warning-icon">
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg></span>
      <span class="text-xs"> {{ glosa }}:     {{ Array.isArray(glosa2) ? glosa2.join(", ") : '' }}</span>
    </div>
  </div>

</template>
<script>



export default {
  props: ["glosa", "glosa2"],


  components: {

  },
  data() {
    return {

      descripcion: ''

    }


  },
  created() { },
  mounted() {
    
  },
  beforeUnmount() {

  },
  watch: {
   
  }

};
</script>
