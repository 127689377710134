<template>

    <div class="bg-white">
  
        <div class="p-1 bg-white">
              <div class="flex items-center">
                <div class="flex-shrink-0"></div>
                <div class="ml-5 flex-1 text-left">
                  <dl>
                    <dt class="font-semibold    text-cyan-900 pl-0 h-10 p-2 ">
             {{ f.nombre }} 
                    </dt>
                   
                    <dt class="font-semibold  text-xs   text-cyan-500 pl-0  ">
                      {{ f.descripcion }}
                    </dt>

                    <dd class="mt-2 text-xs pr-4">
                      <ConsumoOxigeno v-if="seriesO2Loaded === true" :idFaena="f.id"   :consumo_oxigeno="f.consumo_o2"
                        :seriesFaenas="seriesFaenas"></ConsumoOxigeno>

                    </dd>
                    <dd class="mt-2 text-xs">
                      <span class="font-semibold ">Modos de Operación</span>
                      <div class="flex">
                        <!-- Primera columna: Ocupa el 30% del ancho del contenedor -->
                        <div class="pl-0">
                          <ModosOperacionMensual v-if="seriesLoaded === true" :idFaena="f.id" :mensual="f.modos_op">
                          </ModosOperacionMensual>
                        </div>

                        <!-- Segunda columna: Ocupa el ancho restante -->
                        <div class="flex-1">
                          <FaenaDonut v-if="seriesLoaded === true" :idFaena="f.id" :mensual="f.modos_op"></FaenaDonut>

                        </div>
                      </div>

                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-0 py-3">
              <div class="text-sm">

                <a @click="loadHistorial(f)"
                  class="cursor-pointer w-18 h-10 mt-2 mb-2 ml-1 mr-1 inline-block rounded-lg  px-4   py-2 bg-purple-900 text-base font-semibold leading-7 text-white    ">

                  <div class="flex items-center">
                    <div style="height:22px;width:8px"></div>
                    <div class="ml-0 pr-1">Historial</div>

                  </div>

                </a>
                <a @click="loadCentros(f)"
                  class="cursor-pointer w-18 h-10  mt-2 mb-2 ml-1 mr-1  inline-block rounded-lg  px-4   py-2 bg-gray-700  text-base font-semibold leading-7 text-white    ">

                  <div class="flex items-center">
                    <div style="height:22px;width:25px"><img src="../../assets/barchart.gif" height="22"></div>
                    <div class="ml-2">Live</div>

                  </div>

                </a>






              </div>
            </div>
    </div>
</template>

<script>
//import axios from "axios";

import FaenaDonut from "./FaenaDonut";
import ConsumoOxigeno from "./ConsumoOxigeno";
import ModosOperacionMensual from "./ModosOperacionMensual"; 
//import AuthHeader  from "@/services/auth.header";
export default {
  name: "FaenasCliente",
  props: ["f"],
  emits: ['chgFaena', 'chgFaenaCentros', 'sendDivisionesMenuLat'],
  components: {
    FaenaDonut,
    ModosOperacionMensual,
    
    ConsumoOxigeno,
   

  },
  mounted() {
    this.datos_mensuales = [60, 20, 10, 10, 0]
    // this.loadData();
    this.loadDataSesion();
    this.enviaDivisionesMenuLat();
    this.seriesO2Loaded = true;
    this.seriesLoaded = true;
    this.getFecCalendar(this.fechasSeleccionadas);

  },
  data() {
    return {
      fechasSeleccionadas: ['01 11 2023', '30 11 2023'],
      mostrar: false,
      showModalMapa: false,
      showModalAyuda: false,
      seriesLoaded: false,
      seriesO2Loaded: false,
      showPanelAyuda: false,
      datos_mensuales: [],
      faenas: [{}],
      cards: [{}],
      objFaenas: [{}],
      seriesFaenas: [],
      seriesFechas: [],
      nomMes: ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      seriesMedicionesOxigeno1: [],
      seriesMedicionesOzono1: []
    };
  },
  methods: {
     
    loadDataSesion() {
      this.faenas = this.$store.state.login.userLogin.info.faenas;
    },


    enviaDivisionesMenuLat() {

      this.$emit("sendDivisionesMenuLat", this.faenas);
    },
    loadMapa() {
      console.log('LOAD MAPA')
      this.showModalMapa = true
    },
    closeModal() {
      this.showModalMapa = false
    },
    loadDivisiones(f) {

      this.$emit("chgFaena", f);
    },
    loadCentros(f) {

      this.$emit("chgFaenaCentros", f);
    },
    loadHistorial(f) {

      this.$emit("chgHistorialCentros", f);
    },
    getFecCalendar(f) {

      //  console.log('calendario', f);
      /* [ "01 10 2023",   "31 10 2023"]   */

      if (f[0] == '' || f[1] == '') return false;


      const startDate = this.convertirFormatoFecha(f[0])
      const endDate = this.convertirFormatoFecha(f[1])
      this.seriesFechas = this.generaSerieFechas(new Date(startDate), new Date(endDate))
      this.seriesMedicionesOxigeno1 = this.manipulaSeriesFechas(4, 6)
      this.seriesMedicionesOxigeno2 = this.manipulaSeriesFechas(6, 8)
      this.seriesMedicionesOzono1 = this.manipulaSeriesFechas(100, 300)
      this.seriesMedicionesOzono2 = this.manipulaSeriesFechas(300, 700)
      this.mostrar = true

    },

    manipulaSeriesFechas(min, max) {
      let dataFaena = []
      /*
       seriesFechas
       *[ "2023-10-01".."2023-10-31"]*/
      for (let x = 0; x < this.faenas.length; x++) {

        let data = []
        for (let i = 0; i < this.seriesFechas.length; i++) {

          let dma = this.convertirFormatoFechaDMA(this.seriesFechas[i])
         // console.log(dma)



          let f = Date.parse(dma + ' 00:00:00 GMT')
          let n = parseFloat(this.getRnd(min, max));
          data.push([f, n])

          f = Date.parse(dma + ' 04:00:00 GMT')
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n])

          f = Date.parse(dma + ' 08:00:00 GMT')
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n])

          f = Date.parse(dma + ' 12:00:00 GMT')
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n])

          f = Date.parse(dma + ' 16:00:00 GMT')
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n])

          f = Date.parse(dma + ' 20:00:00 GMT')
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n])




        }
        let medicion = { name: this.faenas[x].nombre, data: data }
        dataFaena.push(medicion)


      }
      return dataFaena;

      //this.chartOptions.series  = dataFaena

    },
    generaSerieFechas(startDate, endDate) {
      const dates = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {

        dates.push(currentDate.toISOString().substring(0, 10));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },


    convertirFormatoFecha(fechaOriginal) {

      if (!fechaOriginal) return false;

      const partes = fechaOriginal.split(" ");
      if (partes.length === 3) {
        const dia = partes[0];
        const mes = partes[1];
        const año = partes[2];

        const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

        if (!isNaN(nuevaFecha)) {

          const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
          return fechaFormateada;
        }
      }


      return null;
    },

    convertirFormatoFechaDMA(fechaOriginal) {
      try {
        const partes = fechaOriginal.split("-");
        if (partes.length === 3) {
          const dia = partes[0];
          const mes = this.nomMes[Number(partes[1])];
          const año = partes[2];
          const nuevaFecha = `${año} ${mes} ${dia}`;
          return nuevaFecha;
        }
      } catch (error) {
        console.log(error)
      }
      return null;
    },

    getRnd(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(2)
    },
    showModalHelp() {
      console.log("show modal");
      this.showModal = true;
    },
    handleDragOVer(event){
      console.log('over')
      event.preventDefault()
    },  
    handleDragEnter(event){
      console.log('enter', event)
    },
     handleDrop(){
      console.log('DROP FAENA')
     
    }
    
  }
  ,
  computed: {

  },

};
</script>
