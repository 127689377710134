 
/* arreglo de objetos jaulas
[
  { id : 4, nombre:'', visible:boolean},
 
]
*/
 
const state = {
    
    Jaulas: [],
    
  };
  const getters = {
    
    getJaulas: (state) => {
     
        return state.Jaulas;
     
    },
    
  };
  const mutations = {
    setJaulas(state, list) {
      state.Jaulas = list;
    }, 
    jaulasCreate(state, arrayObj) {
      
      state.Jaulas = arrayObj
    },
    jaulaAdd(state, newobj) {
      
      state.Jaulas.push(newobj);
    },
    jaulaRemove(state, newobj){
      let s =  state.Jaulas;
      state.Jaulas= s .filter(jaula => {
        if (jaula.id !== newobj.id) {
            return  jaula;  
        }
       });  
    },
    jaulaUpdVisible(state,newobj)
    {
     let ok = false; 
     state.Jaulas.forEach(jaula => {
      if (jaula.id === newobj.id) {
          ok = true;
          jaula.visible = newobj.visible;
              
      }
     });  
     if (!ok){
      state.Jaulas.push(newobj);
     }
    }
  };
  
  const actions = {
    setJaulas({ commit }, list) {
      commit("setJaulas", list);
    },
    jaulaCreate({ commit }, obj) {
      commit('jaulaCreate', obj);
    },
    jaulaUpdVisible(context, f){
      console.log('jaulaUpdVisible')
      context.commit("jaulaUpdVisible", f);
    },
    jaulaAdd(context, f){
      console.log('addjaula')
      context.commit("jaulaAdd", f);
    },
    jaulaRemove(context, f){
      console.log('removejaula')
      context.commit("jaulaRemove", f);
    }
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  };
  