<template>
    <div class="p-0 ">
        
        <apexchart v-if="series.length > 0 "
        type="donut"
        ref="realtimePie"
        :options="chartOptions" :series="series"></apexchart>

        <div v-if="!series.length "  class="flex justify-center items-center w-full h-full text-center">
            <span class="text-2xl text-gray-500">Sin datos de generación  </span>
       </div>
    </div>
    
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
 
export default {
    props: [
        "mensual","idFaena"
    ],
    beforeMount() {
          
           
       
        switch(this.idFaena){
            case 1: this.series=[60, 1 ,  24, 12,  4];break;
            case 2: this.series=[65, 1,  10, 15, 10];break;
            case 3: this.series=[60,  1, 20, 16,  4];break;
            default: this.series = []
           }
    },
    components: {
        apexchart: VueApexCharts,
    },


    data: function () {
        return {

            series: Array,
           // series: this.mensual,
           chartOptions: {
                chart: {
                    type: 'donut',
                },
                //colors: ['#4596f9', '#40ce71', '#FB923C', '#FACC14', '#F87171'],
                //labels: ['Standard', 'Eco', 'Max Load',  'Aire', 'Stand-by'],
                colors: ['#4596f9',  '#40ce71',  '#FB923C', '#FACC14', '#EF4444'],
                labels: ['STANDARD', 'ECO',     'MAXLOAD',   'AIRE', 'STANDBY'],
                dataLabels: {
                    enabled: false,
                    style: {
                        colors: ['#4596f9',   '#FB923C', '#FACC14', '#EF4444']
                       //  colors: ['blue', 'green', 'orange', 'yellow', '#000000'],
                    }
                } ,

                legend: {
                    show: false
                },
                plotOptions:{
                    
                pie:{
                    offsetX: 0,
                    offsetY: 0,
                    customScale:1,
                    donut:{
                        background: 'transparent',
                        size:"1%",
                       
                    }
                }
            }


            },

        }
    }
}
</script>