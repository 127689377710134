<template>

    <div v-if="this.showModalVisibilidad" id="panelayuda" class=" relative z-10" aria-labelledby="slide-over-title"
        role="dialog" aria-modal="true">

        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden ">
                <div class="pointer-events-none fixed inset-y-0 right-0 flex  pl-0 max-w-[100%]">
                    <div class="pointer-events-auto  w-screen   max-w-2xl">
                        <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                            <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                <div class="flex items-center justify-between">
                                    <div class="">



                                    </div>




                                    <div className="grid grid-cols-1 grid-rows-1 gap-2 ml-2">

                                        <div class="pl-12">
                                            <h2 class="text-1xl font-bold tracking-tight text-white sm:text-4xl">
                                                Calendario consultas
                                            </h2>
                                        </div>
                                    </div>



                                    <div>








                                    </div>


                                    <div class="ml-3 flex h-7 items-center">
                                        <button @click="close()" type="button"
                                            class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white ">
                                            <span class="sr-only">Close panel</span>
                                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                stroke="currentColor" aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="pt-12 pl-12 ">





                                <h1 class="text-1xl font-extrabold text-left text-cyan-800">
                                    Seleccione fechas de consulta
                                </h1>
                               
                                <CalendarioResumen :fechasSeleccionadas="fechasSeleccionadas"
                                    @getFecCalendar="getFecCalendar">
                                </CalendarioResumen>


                                <div v-if="mostrarSpinner == true">
                                    <SpinnerLoading></SpinnerLoading>
                                </div>










                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CalendarioResumen from "../../calendar/CalendarioResumen";



export default {
    emits: [
        "handleVisibilidad"
    ],
    components: {

        CalendarioResumen,
    },
    props: {
        nomedificio: String,
        showModalVisibilidad: Boolean,
        objJaulas: Array
    },
    methods: {
        close() {

            this.$emit("close")
        },
        
        calculaHoy30Dias() {

            let hoy = new Date();
            let diaHoy = String(hoy.getDate()).padStart(2, '0');
            let mesHoy = String(hoy.getMonth() + 1).padStart(2, '0');
            let añoHoy = hoy.getFullYear();
            let fechaHoy = `${diaHoy} ${mesHoy} ${añoHoy}`;



            let hace30Dias = new Date();
            hace30Dias.setDate(hoy.getDate() - 30);
            let diaHace30 = String(hace30Dias.getDate()).padStart(2, '0');
            let mesHace30 = String(hace30Dias.getMonth() + 1).padStart(2, '0');
            let añoHace30 = hace30Dias.getFullYear();
            let fechaHace30Dias = `${diaHace30} ${mesHace30} ${añoHace30}`;

            return [fechaHace30Dias, fechaHoy]

        },
        getFecCalendar(f) {
            
            this.$store.state.gotolive=false;
            this.startDate = this.convertirFormatoFecha(f[0]); //2023-11-01
            this.endDate = this.convertirFormatoFecha(f[1]);
            this.$emit("handleRangoFechas", {'startDate':this.startDate, 
                                             'endDate':this.endDate});

        },

        convertirFormatoFecha(fechaOriginal) {
            if (!fechaOriginal) return false;

            const partes = fechaOriginal.split(" ");
            if (partes.length === 3) {
                const dia = partes[0];
                const mes = partes[1];
                const año = partes[2];

                const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

                if (!isNaN(nuevaFecha)) {
                    const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
                    return fechaFormateada;
                }
            }

            return null;
        },
    },
    data() {
        return {
            showPanel: Boolean,
            objVisibilidad: [],
            fechasSeleccionadas: [],

        }
    },
    created() {

        this.showPanel = this.showModalVisibilidad


    },
    mounted() {
        this.fechasSeleccionadas = this.calculaHoy30Dias()

    }

}
</script>