<template>
    <div>

        <div class="p-4">
            <div class="min-w-0 flex-1  text-left">

                <span class="  text-1xl font-extrabold text-left text-cyan-800">
                    Consumo    
                </span>
                <span class="  text-1xl font-extrabold text-left  text-cyan-800">
                    Toneladas de oxígeno 
                </span>

                <span class="  text-1xl  text-left ">
                    <div class="grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-3 pl-8">
                    <div class="rounded-lg shadow-md"><AlertDummy v-show="this.categoriaMeses[0]!==''" :glosa="this.categoriaMeses[0]"></AlertDummy></div>
                    <div class="rounded-lg shadow-md"><AlertDummy v-show="this.categoriaMeses[1]!==''" :glosa="this.categoriaMeses[1]"></AlertDummy></div>
                    <div class="rounded-lg shadow-md"> <AlertDummy v-show="this.categoriaMeses[2]!==''" :glosa="this.categoriaMeses[2]"></AlertDummy></div>
                    </div>
                </span>

            </div>

            <div class="w-full   ">
             {{ fs }}
                <VueApexCharts v-if="result_mediciones == true" ref="demoChart" :options="chartOptions"
                    :series="series"></VueApexCharts>
                <SpinnerLoading v-show="result_mediciones !== true"></SpinnerLoading>
            </div>

        </div>



    </div>
</template>
<style>
.chart-container2 {
    height: 400px;
}
</style>
<script>
import { ref } from "vue";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import SpinnerLoading from "../SpinnerLoading"
import AlertDummy  from "../AlertDummy.vue";

export default {
    props: ['idFaena', 'label', 'idJaula', 'dias', 'aammConsulta' ],
    emits: ['ampliaGrafico', 'muestraFoto'],
    components: {
        VueApexCharts, SpinnerLoading, AlertDummy
    },
    setup() {
  
        const dataAcumulado = ref([0,0,0]); // Hacer reactivo
        const dataCapMaxima = ref([0,0,0]);
        const valorMaxY0 = ref(0);
        const valorMinY0 = ref(0);
        const series = ref([
            {
                name: "Acumulado",
                data: dataAcumulado
            }, {
                name: "Capacidad Máxima",
                data: dataCapMaxima
            }
        ]);
        const chartOptions= {
                chart: {
                    type: 'bar',

                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                colors: ['#4ddda4', '#3ca0fc'],
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '14px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    text: '',
                    align: 'left'
                },
                tooltip: {
                    enabled: false,
                    shared: true,
                    intersect: false
                },
                xaxis: {
                  
                    labels: {
                             formatter: (value) => value, // Permite HTML en las etiquetas
                    },
                    categories: [],
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: "#FF1654",
                        },
                        labels: {
                            style: {
                                colors: "#FF1654",
                            },
                            formatter: function(value) {
                                   
                                    return value;
                             }

                         },
                        title: {
                          
                            style: {
                                color: "#FF1654",
                            },
                        },
                    },

                ],

        }
        return { dataAcumulado, dataCapMaxima, series, valorMaxY0, valorMinY0,chartOptions };
    },
    data() {
        return {
            fs: '',
            
            mostrarSpinner: true,
            result_mediciones: false,
            seriesDiarias: [],
            seriesAcumulaciones: [],
            dataType: '',
            glosaDataType: false,
            arrayLecturas: [],
            arrayAcumulaciones: [],
            mesActual: 0,
            categoriaMeses: [],
            fecActual: '',
            mostrarGrafico: false,
            aammLocal: '',
            mesAnio: [],
            nomMes: [
                '', 'Enero', 'Febrero', 'Marzo', 'Abril',
                'Mayo', 'Junio', 'Julio', 'Agosto',
                'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],

            Acumulado: [],
            CapMax: [],
      



        }

    },
    methods: {
        modal() {
            this.showModal = true;
        },
        propagajaula() {
            this.$emit("muestraFoto", this.idJaula, this.label);
        },

       async loadDatosMensuales(aammConsulta) {
            this.result_mediciones = false;
            this.mostrarSpinner = true;
            let f = aammConsulta.split('-');
            let mm = parseInt(f[1])
            let aa = parseInt(f[0]);
            const { arrayMeses, mmaa } = this.mesesPrevios(mm, aa, 3);
            this.categoriaMeses = arrayMeses;
            this.chartOptions = {
                ...this.chartOptions,
                xaxis: { categories: this.categoriaMeses },


            };
            
            this.dataAcumulado = []
         
            await this.getUnitGasInjection(mmaa[0].startDate, mmaa[0].endDate, 'N',0)
            await this.getUnitGasInjection(mmaa[1].startDate, mmaa[1].endDate, 'N',1)
            await this.getUnitGasInjection(mmaa[2].startDate, mmaa[2].endDate, 'N',2)

             
               

              
            this.chartOptions = {
                    ...this.chartOptions, // Mantén las opciones existentes
                    xaxis: {
                            categories: this.categoriaMeses, // Asigna las nuevas categorías
                            labels: {
                             formatter: (value) => value, // Permite HTML en las etiquetas
                            },
                        }
                    };

            
            
            

            this.result_mediciones = true;
            this.mostrarSpinner = false;
  

          

        },
        mesesPrevios(mes, aa, cantidad) {
            let meses = [];
            let mmaa = [];
            let contador = 1;


            while (contador <= cantidad) {
               

                const { startDate, endDate } = this.startEndDate(mes, aa);

                meses.push(this.nomMes[mes])
                mmaa.push({ 'mm': mes, 'aa': aa, 'startDate': startDate, 'endDate': endDate });
                contador++;
                mes--;
                if (mes == 0) {
                    mes = 12;
                    aa--;
                }
            }

            return {
                arrayMeses: meses.reverse(),
                mmaa: mmaa.reverse()
            };
        },

        startEndDate(mm, aa) {
            const monthIndex = mm - 1;
            const startDate = new Date(aa, monthIndex, 1);
            let endDate;
            if (mm == new Date().getMonth() + 1 &&  aa == new Date().getFullYear() ) {
                // indice mes es siempre -1
                mm--;
                mm = mm < 10 ? `0${mm}` : `${mm}`;
                endDate = new Date(aa, mm , this.getDayToday() - 1 );
                 
            }else{
                endDate = new Date(aa, monthIndex + 1, 0);
            }
           
            return {
                startDate: startDate.toISOString().split('T')[0],  
                endDate: endDate.toISOString().split('T')[0],     
            };
        },
        getDayToday() {
            const today = new Date();
            const day = today.getDate();
            return day < 10 ? `0${day}` : `${day}`; // Formatear con dos dígitos
        },
        async getUnitGasInjection(startDate, endDate, es_faena_natural,indice) {

         
            let dataFaena = [];
            try {
                let arrayLecturasLocal = 0;
                const apiKey         = this.$store.state.login.tokenLogin;
                const objStoreFaenas = this.$store.state.faenas;
                let arrayFaenas = [];
              
                this.objFaena = objStoreFaenas.faenas.filter(faena => faena.id == this.idFaena);
               
                arrayFaenas = this.objFaena;
                
                  
                    // todas las jaulas de la Faena
                    let objFaena = arrayFaenas[0];
                   
                    let jaulas = objFaena.unidades.filter(faena => faena.natural == es_faena_natural);

                   
                    // Array de promesas para las solicitudes a axios
                    // Si hay N elementos (jaulas) en el array jaulas, entonces se crearán N promesas en total,
                    // una para cada elemento del array.
                    const jaulaPromises = jaulas.map((jaula) => {

                        this.glosaDataType=false;
                        if (jaula.id !== undefined) {
                            const requestData = {
                                idUnit: jaula.id,
                                startDay: `${startDate} 00:00:00`,
                                finishDay: `${endDate} 23:59:59`,
                            };
                            return axios
                                .post("/api/ctl/unitGasInjection", requestData, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `${apiKey}`,
                                    },
                                })
                                .then((response) => {

                                    this.glosaDataType =  response.data.payload.dataType == 'dummy' ? true:false;
                                    let gas = response.data.payload.gas;
                                    gas.forEach(g => {
                                      
                                        // inyection contiene un simple array  
                                        g.inyection.forEach(med => {

                                             
                                                
                                                
                                                med = parseFloat(med);  
                                                arrayLecturasLocal +=med ;


                                                // if (indice == 0) console.log("totals", indice, med, arrayLecturasLocal[indice])


 


                                        });
                                    });



                                })
                                .catch((error) => {
                                    console.log("Error al obtener datos de la unidad", error);
                                    return [];
                                });
                        }

                    });

                    await Promise.all(jaulaPromises);

          

               

                this.valorMaxY0 = 0;
                this.valorMinY0 = 0;
                // Array Reactivo    =  data []
                this.dataCapMaxima = [];

                // Array Reactivo    =  data []
                this.dataAcumulado[indice]= arrayLecturasLocal == null ? 0 : arrayLecturasLocal.toFixed(2) ;
                // data [ ]        =   Array Reactivo 
                //this.seriesDiarias = this.series;

                if (this.glosaDataType){
                    if (indice >= 0 && indice < this.categoriaMeses.length) {
                     this.categoriaMeses[indice] = `${this.categoriaMeses[indice]} simulado`;
                      
                    }
                }
                 
               
               




                return dataFaena;



            } catch (error) {
                console.log("error procesando faenas", error);
            }
        },


    },
    created() {

    },
    mounted() {







    },
    beforeUnmount() {


    },
    watch: {
        aammConsulta: function (valor) {



            this.loadDatosMensuales(valor)
        },
        idFaena: function () {

            this.loadDatosMensuales(this.aammConsulta)
        }    

    }
};
</script>