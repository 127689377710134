<template>
 <div>
  
  <ModalLogin :show="showModal" @close="showModal = false"></ModalLogin>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="https://www.oxytek.cl/images/logo_oxytek_2.png"
        alt="Oxytek"
      />
    
    </div>
 
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      {{ this.mensaje }}
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      
        <form class="space-y-6" @submit.prevent="handleSubmit">
          <div>
            <label for="userName" class="block text-sm font-medium text-gray-700"
              >Email</label> 
            <div class="mt-1">
              <input
                id="userName"
                v-model="userName"
                name="userName"
                type="text"
                autocomplete="userName"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div class="mt-1">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              
              
            </div>

            <div class="text-sm">
             
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="
                w-full
                flex
                justify-center
                py-2
                px-4
                border border-transparent
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-white
                bg-indigo-600
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
            >
              Entrar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
         <div  v-if="isOnline == false" class="sticky bottom-0 bg-red-500 text-white text-center py-4" style='z-index:10000000'>
         <p>Sin conexión a la red</p>
        </div>
</div>
</template>
<script>
 
import { mapActions } from 'vuex';
import router from "../../router";
import ModalLogin from "./ModalLogin";
import DOMPurify from 'dompurify'; 
export default {
  name: "LoginRoot",
  mounted(){
    window.localStorage.removeItem('user');
  },
  data() {
    return {
      isOnline: false,
      userName: "",  
      password: "",  
      showModal: false,
      status:'0',
      token:'',
      mensaje:''
    };
  },
  created(){
    this.checkInternet();
    window.addEventListener("online", this.checkInternet);
    window.addEventListener("offline", () => (this.isOnline = false));
  },
  components: {
    ModalLogin,
  },
  methods: {
    ...mapActions('auth', ['login']),
    async checkInternet() {
      try {
       
        await fetch("https://monitorweb.oxytek.cl/", { mode: "no-cors" });
        this.isOnline = true;
      } catch (error) {
        this.isOnline = false;
      }
    },
    updModal() {
      this.showModal = true;
    },
    async handleSubmit() {

      try {
       
       
        let e= DOMPurify.sanitize(this.userName);
        let p =  DOMPurify.sanitize(this.password);
        await this.$store.dispatch('login', {e,p });
        if (this.$store.state.login.statusLogin==200){
          this.mensaje =" Ingresando a sitio de monitoreo ..."
        
          router.push("/home");
        }else{
          this.updModal();
        }

        /*
        const resp = await axios.post(
          "/auth/Login",
          {
            user: this.userName,
            password: this.password,
          }
        );
 
        this.status=resp.data.status;
     
        switch (this.status) {
          case 200:
         
            resp.data.userName= this.userName
            this.token=resp.data.payload.sessionToken;
            resp.data.payload.sessionToken='';
            localStorage.setItem('user',JSON.stringify(resp.data.payload));
            this.$store.dispatch('user',resp.data.payload);
            this.$store.dispatch('token',this.token);
            router.push("/home");
            break;
          case 300:
           this.updModal();
           break;
        }
        */
      } catch (err) {
        this.updModal();
        console.log(err);
        this.mensaje =err;
       
      }
    },
  },
};
</script>
