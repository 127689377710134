<template>

    <ModalLogin :show="showModal" @close="showModal = false"></ModalLogin>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">




        <div class="mt-0 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-0 px-2 shadow sm:rounded-lg sm:px-10">

                <form id='chgpass' v-on:submit.prevent="onSubmit" class="space-y-6">
                    <div class="mt-1">
                        <div class="relative w-full mb-3">
                            Hola {{ datosUsuario.nombreUsuario }}

                        </div>
                        <div class="relative w-full mb-3">
 
                            <label for="password0" class="block text-sm font-medium text-gray-700">
                                Ingrese Password Actual  

                            </label>
                            <input v-on:blur="chkPassEndpoint" id="password0" v-model="password0" name="password0" :type="type0"
                                autocomplete="current-password" required="" 
                                class="border-1 p-4 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" />
                            <div class=" absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                                <p class=" mt-5"> <a @click="showPassword0">{{ btnText0 }}</a></p>
                            </div>
                            
                        </div>
                        <p class="mt-2 text-sm text-red-500">{{ err_pass_0 }}</p>

                    </div>

                    <div>

                        <div class="mt-1">

                            <div class="relative w-full mb-3">

                                <label for="password1" class="block text-sm font-medium text-gray-700">
                                    Ingrese Nueva Password 

                                </label>

                                <input id="password1" v-model="password1" name="password1" :type="type1"
                                    autocomplete="current-password" required=""  @keyup="chkPass" 
                                    class="border-1 p-4 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" />
                                <div class=" absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                                    <p class=" mt-5"> <a @click="showPassword1">{{ btnText1 }}</a></p>
                                </div>
                             
                            </div>
                           

                        </div>
                    </div>
                    <div>

                        <div class="mt-1">



                            <div class="relative w-full mb-3">
                                <label for="password2" class="block text-sm font-medium text-gray-700">
                                    Confirme Nueva Password <span class="text-red-500">{{ err_pass_2 }}</span>

                                </label>
                                <input id="password2" v-model="password2" name="password2" :type="type2"
                                    autocomplete="current-password" required="" @keyup="chkPass2" 
                                    class="border-1 p-4 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" />
                                <div class=" absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                                    <p class=" mt-5"> <a @click="showPassword2">{{ btnText2 }}</a></p>
                                </div>
                            
                            </div>
                            <p class="mt-2 text-sm text-red-500">{{ err_pass }}</p>
                        </div>
                    </div>
                   
                    <div>
                        <p v-if="passwordChgOK" class="mt-2 mb-3 text-sm text-blue-500">{{ msg_success }}</p>
                          
                        <button type="button" @click="handleSubmit"
                            class="  w-full   flex   justify-center  rounded-md  border-gray-300 shadow-sm   py-4   px-4   border border-transparent
                   text-sm  font-medium  text-white   bg-indigo-600   hover:bg-indigo-700  focus:outline-none  focus:ring- focus:ring-offset-2    focus:ring-indigo-500  ">
                            Confirmar Cambio
                        </button>

                        <button type="reset" 
                         @click="resetForm"
                        class="w-full   flex   justify-center  rounded-md  border-gray-300 shadow-sm py-4 my-4   px-4   border border-transparent
                              text-sm  font-medium    bg-white   focus:ring- focus:ring-offset-2 " ref="cancelButtonRef">Limpiar
                            formulario</button>
                    </div>
                    <div>
                       
                    </div>
                    <div id="message" class=" text-black relative p-5 mt-2">
                        <h3 class="font-bold">Password debe contener:</h3>
                        <p  :class="{
                                'text-red-500': !isValidMinus,
                                'text-green-500': isValidMinus,
                                'flex items-center text-lg py-2 pl-9': true
                            }">
                             <span   class="relative left-[-35px] mr-2" v-text="isValidMinus ? '✔' : '✖'"></span>
                             una   letra <b class="font-bold ml-1">minúscula</b>  
                        </p>
                        <p id="capital" :class="{
                                'text-red-500': !isValidMayus,
                                'text-green-500': isValidMayus,
                                'flex items-center text-lg py-2 pl-9': true
                            }">
                             <span class="relative left-[-35px] mr-2"  v-text="isValidMayus ? '✔' : '✖'"></span>
                             una  letra <b class="font-bold ml-1">mayúscula</b>  
                        </p>
                        <p id="number" :class="{
                                'text-red-500': !isValidNumber,
                                'text-green-500': isValidNumber,
                                'flex items-center text-lg py-2 pl-9': true
                            }">
                            <span class="relative left-[-35px] mr-2"  v-text="isValidNumber ? '✔' : '✖'"> </span>
                              <b class="font-bold ml-1">un número</b>
                        </p>
                        <p id="length" :class="{
                                'text-red-500': !isValidLength,
                                'text-green-500': isValidLength,
                                'flex items-center text-lg py-2 pl-9': true
                            }">
                            <span class="relative left-[-35px] mr-2"  v-text="isValidLength ? '✔' : '✖'"></span>
                            <b class="font-bold ml-1">entre 8 y 20 caracteres </b>
                        </p>
                       
                        <p id="characspecial" :class="{
                                'text-red-500': !isValidSpecial,
                                'text-green-500': isValidSpecial,
                                'flex items-center text-lg py-2 pl-9': true
                            }">
                            <span class="relative left-[-35px] mr-2"  v-text="isValidSpecial ? '✔' : '✖'"></span>
                            <b class="font-bold ml-1">un caracter especial</b>
                        </p>
                    </div>


                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import DOMPurify from "dompurify";
import router from "../../router";
import ModalLogin from "../login/ModalLogin";
import { mapGetters } from "vuex";
export default {

    name: "FormCambiarPass",
    mounted() {


        let user = this.$store.state.login.userLogin.info;

        this.datosUsuario = {

            nombreUsuario: user.nombres + " " + user.apellido1,
            userName: user.userName,
            idEmpresa: user.idEmpresa,


        };
    },
    data() {
        return {
            isValidPassOriginal: false,
            isValidMinus: false,
            isValidMayus: false,
            isValidNumber: false,
            isValidLength: false,
            isValidSpecial: false,
            password0: "",
            password1: "",
            password2: "",
            showModal: false,
            type0: 'password',
            type1: 'password',
            type2: 'password',
            btnText0: 'Mostrar',
            btnText1: 'Mostrar',
            btnText2: 'Mostrar',
            err_pass: "",

            err_pass_0: "",
            err_pass_1: "",
            err_pass_2: "",
            passwordChgOK: false,
            msg_success: '',
            datosUsuario: {

                nombreUsuario: '',
                userName: '',
                idEmpresa: ''
            }
        };
    },
    components: {
        ModalLogin,
    },
    methods: {
        return_home() {
            router.push('/home')
        },
        updModal() {
            this.showModal = true;
        },
        showPassword0() {
            if (this.type0 === 'password') {
                this.type0 = 'text'
                this.btnText0 = 'Ocultar'
            } else {
                this.type0 = 'password'
                this.btnText0 = 'Mostrar'
            }
        },
        showPassword1() {
            if (this.type1 === 'password') {
                this.type1 = 'text'
                this.btnText1 = 'Ocultar'
            } else {
                this.type1 = 'password'
                this.btnText1 = 'Mostrar'
            }
        },
        showPassword2() {
            if (this.type2 === 'password') {
                this.type2 = 'text'
                this.btnText2 = 'Ocultar'
            } else {
                this.type2 = 'password'
                this.btnText2 = 'Mostrar'
            }
        },
         chkPass(){
           let password = this.password1;
           this.isValidMinus = password.match(/[a-z]/g)?  true:false;
           this.isValidMayus =password.match(/[A-Z]/g) ? true:false;
           this.isValidNumber =password.match(/[0-9]/g) ? true: false;
           this.isValidLength = password.length >= 8 && password.length <= 20 ? true : false;
           var regex = /[^\w\s]/
           this.isValidSpecial= regex.test(password) ? true : false;
         
           let result = [this.isValidMinus, 
                         this.isValidMayus, 
                         this.isValidNumber, 
                         this.isValidLength, 
                         this.isValidSpecial];
            if (result.every( v => v == true )){
               
                return true;
            }             
            return false;
        
        },
        chkPass2(){
            if (this.password1 == this.password2){
                    this.err_pass ='';
            }else{
                this.err_pass = 'passwords no coinciden';
            }
        },
        validaPassword() {
            if (this.password0.length == 0) {
               
                this.err_pass_0 = 'Password original requerida'
                return false;
            }
            if (this.password1.length == 0) {
          
                this.err_pass = 'Ingrese nueva password'
                return false;
            }
            if (this.password1 !== this.password2) {
              
                this.err_pass = 'passwords no coinciden'
                return false;
            }

             if (this.chkPass()){
              
                return true;
             }
             return false;
             
            
        },


      async  handleSubmit() {
            if (this.validaPassword()) {
                let e = DOMPurify.sanitize(this.datosUsuario.userName);
                let p = DOMPurify.sanitize(this.password0);
              
                await this.$store.dispatch('login', { e, p });
                if (this.$store.state.login.statusLogin == 200) {
                   this.update()
                } else {
                    this.err_pass_0 = "  ( Password no válida )";
                    this.isValidPassOriginal = false;
                }
               
            }
        },
        async update() {
            try {
               
                 

                this.msg_success= 'Cambiando password';
                const apiKey = this.$store.state.login.tokenLogin;
                const data = {
                    oldPassword: this.password0,
                    newPassword: this.password1,

                };
                console.log(apiKey, "--")
                let url = 'auth/cambiaPass';
                const resp = await axios.post(url, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${apiKey}`
                    }
                });

                switch (resp.status) {
                    case 200:{
                      
                        this.showMsg();
                        
                        break;
                    }
                   
                      
                }
            } catch (err) {
              
                this.msg_success= '';
                this.err_pass_0 = " Hubo un error al invocar endpoint cambio pass";
                 
                console.log(err);
            }
        },
        async chkPassEndpoint() {
            try {

              
                let e = DOMPurify.sanitize(this.datosUsuario.userName);
                let p = DOMPurify.sanitize(this.password0);
                await this.$store.dispatch('login', { e, p });
                if (this.$store.state.login.statusLogin == 200) {
                    console.log('OK CON LOGIN')
                    this.err_pass_0 = "";
                    this.isValidPassOriginal = true;
                } else {
                    this.err_pass_0 = "  ( Password no válida )";
                    this.isValidPassOriginal = false;
                }


            } catch (err) {
                this.updModal();
                console.log(err);
                this.mensaje = err;

            }



        },
        resetForm() {
     
            this.password1= "";
            this.password0= "";
            this.password2= "";

      
    },
        showMsg() {
        let seconds = 2;
        this.err_pass_0 = "";
        this.msg_success= 'Password cambiada exitosamente';
        const timer = setInterval(() => {
            this.passwordChgOK= true;

            seconds--;
            if (seconds < 0) {
                clearInterval(timer); 
                this.passwordChgOK= false; 
                this.resetForm();
            }
        }, 1000); 
        }
    },
    computed: {
        ...mapGetters(["user"]),
    }
};
</script>
