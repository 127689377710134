<template>
  <div> 
 
    <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1   pt-5 text-left">
        
        <span class="text-xs sm:text-xl  text-white font-bold">Monitor de Oxigenación  </span>
       

     </div>     

 </div>
</template>
<script>
 
 
export default {
 

}
</script>