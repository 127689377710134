<template>
<div>
 
     <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1   pt-5 ">
        
        <span class="text-xs sm:text-xl text-white font-bold">Monitor de Oxigenación  </span>
  

     </div>
</div>
</template>
<script>
 
export default {
 props:['nombreFaena'],
 components:{
    
 },
  data(){
     return{
          showModalMapa:false,
          showModalLayout:false,
          showModalAyuda:false,
          fecha_hora: ''
     }
  },
  methods:{

     loadMapa(){
        console.log('LOAD MAPA')
        this.showModalMapa=true
    },
    loadLayout(){
        console.log('LOAD LAYOUT')
        this.showModalLayout=true
    }, 
    loadAyuda(){
        console.log('LOAD AYUDA')
        this.showModalAyuda=true
    },  
    closeModal(){
      this.showModalMapa=false
    },
    closeModalLayout(){
      this.showModalLayout=false
    },
    closeModalAyuda(){
      this.showModalAyuda=false
    },
  }


}
</script>