import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../components/home/HomeView.vue'

import LoginRoot from '../components/login/LoginRoot.vue'
import LoginWebRoot from '../components/login/LoginWebRoot.vue'
import LoginRootCaptcha from '../components/login/LoginRootCaptcha.vue'
import LoginRootCaptchaTest from '../components/login/LoginRootCaptchaTest.vue'

import HomeCliente from '../components/home/HomeCliente.vue'  
import LogoutSession from '../components/LogoutSession.vue'
import FormCambiarPass from '../components/user/FormCambiarPass.vue'
import FormCambiarPassUsers from '../components/user/FormCambiarPassUsers.vue'
import HomeVistaPlanta from '../components/home/HomeVistaPlanta.vue'
import CodigoEnviado from '../components/user/CodigoEnviado.vue'
import CodigoEnviar from '../components/user/CodigoEnviar.vue'

import MonitoreoPlanta from '../components/MonitoreoPlanta.vue'
import HomeProyectos from '../components/home/HomeProyectos.vue'
 
const routes = [
  
  {
    path:'/',
    name:'LoginRootCaptcha',
    component:LoginRootCaptcha
  },
  {
    path:'/logintest',
    name:'LoginRootCaptchaTest',
    component:LoginRootCaptchaTest
  },
  {
    path:'/loginantiguo',
    name:'LoginRoot',
    component:LoginRoot
  } ,
    
  {
    path: '/home',
    name: 'home',
    component: HomeCliente
  },

   
    
  {
    path: '/settings/profile/changepass',
    name: 'formcambiarpass',
    component: FormCambiarPass
  },
  {
    path: '/settings/settings/changepass',
    name: 'formcambiarpassusers',
    component: FormCambiarPassUsers
  },
  {
    path: '/codesent',
    name: 'codesent',
    component: CodigoEnviado
  },
  {
    path: '/codesend',
    name: 'codesend',
    component: CodigoEnviar
  },
  {
    path: '/logout',
    name: 'accounts',
    component: LogoutSession
  },
  {
    path: '/expire',
    name: 'expire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/home/AboutView.vue')
  },
  {
    path: '/homevistaplanta',
    name: 'homevistaplanta',
    component: HomeVistaPlanta
  },
  {
    path:'/weblogin',
    name:'LoginWebRoot',
    component:LoginWebRoot
  }
  ,
  {
    path:'/monitoreo',
    name:'MonitoreoPlanta',
    component:MonitoreoPlanta
  },
  {
    path: '/proyectos',
    name: 'HomeProyectos',
    component:HomeProyectos
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
